@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    margin: 0;
    font-family: "Anton", "Montserrat", "Inter", "Urbanist", "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* make the maximun screen to be 1200px */
    /* max-width: 1440px; */
    background-color: #171718;
    margin-left: auto;
    margin-right: auto;
    padding-left: auto;
    padding-right: auto;
    letter-spacing: 1.28px;
    line-height: auto;
    /* remove line height on text */
}

input:focus {
    outline: none;
}


/* #root {
    max-width: 1200px;
} */


/* add this class to tags when fonts doesnt respond */

.global-font-style {
    font-family: "Anton", "Inter", "Urbanist", "Poppins", sans-serif;
}

.anton-regular {
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.card {
    aspect-ratio: 4 / 5;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
}

.placeholder {
    position: relative;
    overflow: hidden;
}

.placeholder:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100px;
    background: linear-gradient(90deg, #eee, #f4f4f4, #eee);
    animation: gradient 1s infinite ease-in-out;
}

.image-placeholder {
    aspect-ratio: 16 / 12;
}

@keyframes gradient {
    form {
        left: 0%;
    }
    to {
        left: 100%;
    }
}