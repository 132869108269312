.custom180fontsize {
    font-size: 180px;
    background-color: red;
    padding-top: 0px;
    padding-bottom: 0px;
}

.typeofcontactuscomponent {
    width: 31%;
}


/* #large_text_contact {
    font-size: 6.875rem;
} */

.frequentlyAskQuestionStyle {
    width: 49%;
}