.signupsignincard {
    width: 26%;
}

.leftcardinfo {
    width: 45%
}

.largeAccountText {
    font-size: 7.75rem;
}